<template>
  <el-form-item :label="label" :prop="name" :class="{ active: visible }">
    <el-autocomplete
      v-on="listeners"
      v-bind="$attrs"
      :fetch-suggestions="querySearch"
      popper-class="select-option"
      :popper-append-to-body="false"
      :clearable="true"
      :placeholder="placeholder"
    >
      <template slot-scope="{ item }">
        <div class="code">{{ item.code }}</div>
        <span class="value">{{ item.value }}</span>
      </template>
    </el-autocomplete>
  </el-form-item>
</template>

<script>
export default {
  name: 'AutocompleteTemplate',
  props: { name: String, label: String, placeholder: String, codes: Array },
  data() {
    return {
      focus: false
    };
  },
  methods: {
    querySearch(queryString, cb) {
      const codes = this.codes;
      const results = queryString ? codes.filter(this.createFilter(queryString)) : codes;
      // 调用 callback 返回建议列表的数据
      // console.log(queryString);
      cb(results);
    },
    createFilter(queryString) {
      return codes => {
        return codes.value.toLowerCase().includes(queryString.toLowerCase());
      };
    }
  },
  computed: {
    visible() {
      return this.$attrs.value || this.focus || this.placeholder;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit('input', event),
        focus: event => (this.focus = true),
        blur: event => (this.focus = false)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/form/input.scss';
@import '@/assets/css/components/form/autocomplete.scss';
</style>
